import { useEffect, useState } from 'react'
import Page from '../layout/Page'
import { Grid, Typography } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import { getAuthenticator } from '../auth/auth'
import { AzureAdClient } from '../config/config'
import { UserContext } from '../App'
import { useContext } from 'react'
import HelpPage from '../help/HelpPage'

export default function HomePage(props = {}) {
  const user = useContext(UserContext)
  // const { instance } = useMsal()

  // const matchedAccount = instance.getAllAccounts().find((a) => a.tenantId === AzureAdClient.info.tenantId)
  // console.log(matchedAccount)
  // instance.setActiveAccount(matchedAccount)

  // const [account, setAccount] = useState(getAuthenticator().getCurrentAccount())

  // useEffect(() => {    
  //   setAccount(instance.getActiveAccount())
  // },[instance, role])

  const account = {
    idTokenClaims: {}
  }
  const roleCtx = {
    role: {}
  }

  return (
    <Page {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12}><Typography variant="h4" gutterBottom>Project Colt Home</Typography></Grid>
        <Grid item xs={12}><span>Name: {user?.firstName || 'null'}</span></Grid>
        <Grid item xs={12}><span>Email: {user?.email || 'null'}</span></Grid>
        <Grid item xs={12}><span>Department: {user?.department || 'N/A'}</span></Grid>
        <Grid item xs={12}><span>Roles: <ol>{(user?.roles || []).map(r => <li>{r.name}</li>)}</ol></span></Grid>
      </Grid>
    </Page>
  )
}
